// Generated by Framer (a10584e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["P_zkT3aAg", "r3RM5j9QC"];

const serializationHash = "framer-bmuiG"

const variantClassNames = {P_zkT3aAg: "framer-v-1iggy65", r3RM5j9QC: "framer-v-g9dnv9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Free: "P_zkT3aAg", Plus: "r3RM5j9QC"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, DeAuUpW3F: title ?? props.DeAuUpW3F ?? "Plan", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "P_zkT3aAg"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, DeAuUpW3F, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "P_zkT3aAg", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1iggy65", className, classNames)} data-framer-name={"Free"} layoutDependency={layoutDependency} layoutId={"P_zkT3aAg"} ref={refBinding} style={{backgroundColor: "var(--token-58389c29-c015-4427-a57c-637e65cb6404, rgb(235, 235, 235))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} variants={{r3RM5j9QC: {backgroundColor: "var(--token-556a66a6-7f4b-4118-b327-ed374e4a0f03, rgb(173, 31, 255))"}}} {...addPropertyOverrides({r3RM5j9QC: {"data-framer-name": "Plus"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7QmFzaWMtcmVndWxhcg==", "--framer-font-family": "\"Basic\", \"Basic Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-line-height": "1em"}}>Plan</motion.p></React.Fragment>} className={"framer-1ttd71n"} fonts={["GF;Basic-regular"]} layoutDependency={layoutDependency} layoutId={"h4mKqwa8D"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={DeAuUpW3F} variants={{r3RM5j9QC: {"--extracted-r6o4lv": "rgb(255, 255, 255)"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({r3RM5j9QC: {children: <React.Fragment><motion.p style={{"--font-selector": "R0Y7T25lc3QtNTAw", "--framer-font-family": "\"Onest\", \"Onest Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "500", "--framer-line-height": "1em", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>PRO</motion.p></React.Fragment>, fonts: ["GF;Onest-500"]}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bmuiG.framer-135gjsv, .framer-bmuiG .framer-135gjsv { display: block; }", ".framer-bmuiG.framer-1iggy65 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 8px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-bmuiG .framer-1ttd71n { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-bmuiG.framer-1iggy65 { gap: 0px; } .framer-bmuiG.framer-1iggy65 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-bmuiG.framer-1iggy65 > :first-child { margin-left: 0px; } .framer-bmuiG.framer-1iggy65 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 41.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"r3RM5j9QC":{"layout":["auto","auto"]}}}
 * @framerVariables {"DeAuUpW3F":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerjfiBQfqgq: React.ComponentType<Props> = withCSS(Component, css, "framer-bmuiG") as typeof Component;
export default FramerjfiBQfqgq;

FramerjfiBQfqgq.displayName = "CMS / Plan Tag";

FramerjfiBQfqgq.defaultProps = {height: 30, width: 41.5};

addPropertyControls(FramerjfiBQfqgq, {variant: {options: ["P_zkT3aAg", "r3RM5j9QC"], optionTitles: ["Free", "Plus"], title: "Variant", type: ControlType.Enum}, DeAuUpW3F: {defaultValue: "Plan", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerjfiBQfqgq, [{explicitInter: true, fonts: [{family: "Basic", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/basic/v17/xfu_0WLxV2_XKQNy4lDVyR7D.woff2", weight: "400"}, {family: "Onest", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/onest/v8/gNMZW3F-SZuj7zOT0IfSjTS16cPhxx-ZtxFMQWXgSQ.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})